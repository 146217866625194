import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Dropdown, { IDropdownProps } from '@/components/switchback/Dropdown/Dropdown';
import Heading from '@/components/switchback/Heading/Heading';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import { DatePickerModalContent } from './DatePickerModalContent';
import DatePickerModalDesktopFooter from './DatePickerModalDesktopFooter';
import DatePickerModalMobileHeader from './DatePickerModalMobileHeader';

interface IDates {
  from?: Date;
  to?: Date;
}

type TDropdownRestProps = Omit<
  IDropdownProps,
  'customHeader' | 'customFooter' | 'titleContainerClass'
>;

interface IProps extends TDropdownRestProps {
  disableClear?: boolean;
  disabledDays?: {
    from: Date;
    to: Date;
  }[];
  initialFrom?: Date;
  initialTo?: Date;
  minimumDays?: number;
  onSelectDate?: (from: Date | undefined, to: Date | undefined) => void;
  onConfirmDates?: (from: Date, to: Date) => void;
  onClearDates?: () => void;
  onSave?: () => void;
  warning?: string;
  customFooter?: React.ReactNode;
  customHeader?: React.ReactNode;
  disableFrom?: boolean;
  flexibleDays?: number;
  isWishlist?: boolean;
}

const DatePickerModal: React.FC<IProps> = ({
  disableClear,
  disabledDays,
  initialFrom,
  initialTo,
  minimumDays,
  onClearDates,
  onConfirmDates,
  onDismiss,
  onSelectDate,
  onSave,
  show,
  warning,
  customFooter,
  disableFrom,
  flexibleDays,
  customHeader,
  isWishlist = false,
  ...rest
}) => {
  const intl = useIntl();
  const [selectedDates, setSelectedDates] = useState<IDates>({
    from: initialFrom,
    to: initialTo,
  });
  const [confirmedDates, setConfirmedDates] = useState<IDates>(selectedDates);
  const { isAboveTablet } = useBreakpoint();

  const today = new Date();
  const toBeDisabled = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());

  const handleSelectDates = ({ from, to }: IDates) => {
    setSelectedDates({ from, to });
    onSelectDate?.(from, to);

    if (from && to) {
      setConfirmedDates({ from, to });
      onConfirmDates?.(from, to);
    }
  };

  const handleDismiss = useCallback(() => {
    setSelectedDates(confirmedDates);
    onDismiss?.();
  }, [confirmedDates, setSelectedDates, onDismiss]);

  const handleClearClick = () => {
    setSelectedDates({});
    setConfirmedDates({});
    onClearDates?.();
  };

  useEffect(() => {
    setConfirmedDates({ from: initialFrom, to: initialTo });
  }, [initialFrom, initialTo, setConfirmedDates]);

  useEffect(() => {
    if (!show) {
      setSelectedDates(confirmedDates);
    }
  }, [show, setSelectedDates, confirmedDates]);

  return (
    <Dropdown
      customHeader={
        customHeader ?? (
          <>
            <div className="h-12 md:hidden">
              {selectedDates.from || selectedDates.to ? (
                <DatePickerModalMobileHeader
                  from={selectedDates.from}
                  to={selectedDates.to}
                  onClearClick={!disableClear ? handleClearClick : undefined}
                  flexibleDays={flexibleDays}
                />
              ) : (
                <Heading level={2} className="pr-6 text-gray-900 highlight autoType800">
                  {intl.formatMessage({ defaultMessage: 'Add dates', id: 'Fzp8xF' })}
                </Heading>
              )}
            </div>
            {warning && (
              <div className="pb-4 md:mb-5 md:border-b md:border-gray-200 highlight autoType400">
                {warning}
              </div>
            )}
          </>
        )
      }
      customFooter={
        customFooter ??
        ((isWishlist || selectedDates.from || selectedDates.to) && !disableClear && (
          <div className="hidden md:block">
            <DatePickerModalDesktopFooter
              onClearClick={handleClearClick}
              onConfirmDates={onConfirmDates}
              onSave={onSave}
              selectedDatesFrom={selectedDates.from}
              selectedDatesTo={selectedDates.to}
              isWishlist={isWishlist}
            />
          </div>
        ))
      }
      titleContainerClass="border-b border-solid border-gray-200 pb-2"
      onDismiss={handleDismiss}
      show={show}
      {...rest}>
      <DatePickerModalContent
        numberOfMonths={isAboveTablet ? 2 : 3}
        isStacked={!isAboveTablet}
        onChange={handleSelectDates}
        disabledDays={disabledDays}
        disableBefore={toBeDisabled}
        dateTo={selectedDates.to}
        dateFrom={selectedDates.from}
        toMonth={dayjs().add(2, 'year').toDate()}
        minimumDays={minimumDays}
        disableFrom={disableFrom}
        initialFrom={initialFrom}
        isWishlist={isWishlist}
      />
    </Dropdown>
  );
};

export default DatePickerModal;
