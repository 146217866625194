import { Button, EButtonColorVariant, LinkButton } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  onClearClick: () => void;
  onConfirmDates: ((from: Date, to: Date) => void) | undefined;
  onSave?: () => void;
  selectedDatesFrom: Date | undefined;
  selectedDatesTo: Date | undefined;
  isWishlist?: boolean;
}

const DatePickerModalDesktopFooter: React.FC<IProps> = ({
  onClearClick,
  onConfirmDates,
  onSave,
  isWishlist = false,
  selectedDatesFrom,
  selectedDatesTo,
}) => {
  const intl = useIntl();

  return (
    <>
      {isWishlist ? (
        <div className="flex items-center justify-between w-full pt-4">
          <LinkButton label="Clear" onClick={onClearClick} />
          <Button
            label={intl.formatMessage({
              defaultMessage: 'Save',
              id: 'jvo0vs',
            })}
            onClick={
              onSave ||
              (() => {
                if (selectedDatesFrom && selectedDatesTo) {
                  onConfirmDates?.(selectedDatesFrom, selectedDatesTo);
                }
              })
            }
            variant={EButtonColorVariant.Primary}
          />
        </div>
      ) : (
        <div className="text-right">
          <button
            data-testid="clear-btn"
            onClick={onClearClick}
            className="text-gray-900 underline text autoType300">
            {intl.formatMessage({
              defaultMessage: 'Clear',
              id: '2A47mx',
              description: 'DatePickerModalDesktopFooter - Clear button on Calendar Modal footer',
            })}
          </button>
        </div>
      )}
    </>
  );
};

export default DatePickerModalDesktopFooter;
